import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { messaging } from "../../utils/firebase";

import { ToastContainer } from "react-toastify";
import {
  apiLoadAppDataAction,
  apiSendFCMTokenAction,
  apiSendDevicePingAction,
} from "../../sagas/apiCallSaga";
import DeviceSelectionModal from "./Components/DeviceSelectionModal";
import { showError, showInformation, isAppleDevice } from "../../utils/service";
import {
  setNeedToSendTokenAction,
  setFCMTokenAction,
} from "../../reducers/entity/account";
import LoggedInRoute from "./Components/LoggedInRoute";


const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const MainPages = lazy(() => import("../../DemoPages/MainPages"));
const MyPages = lazy(() => import("../../DemoPages/MyPages"));
{
  /*const Applications = lazy(() => import('../../DemoPages/Applications'));
const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Widgets = lazy(() => import('../../DemoPages/Widgets'));
const Elements = lazy(() => import('../../DemoPages/Elements'));
const Components = lazy(() => import('../../DemoPages/Components'));
const Charts = lazy(() => import('../../DemoPages/Charts'));
const Forms = lazy(() => import('../../DemoPages/Forms'));
const Tables = lazy(() => import('../../DemoPages/Tables'));*/
}

const mapStateToProps = (store) => ({
  account: store.entity.account,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadAppData: apiLoadAppDataAction,
      setNeedToSendToken: setNeedToSendTokenAction,
      sendFCMToken: apiSendFCMTokenAction,
      setFCMToken: setFCMTokenAction,
      sendDevicePing: apiSendDevicePingAction,
    },
    dispatch
  );

class AppMain extends Component {
  componentDidMount() {
    let { accessToken } = this.props.account;

    if (undefined === accessToken) return;

    this.props.loadAppData();

    if (!messaging) {
    } else {
      messaging.onTokenRefresh(() => {
        this.props.setNeedToSendToken(true);
      });

      messaging.onMessage((payload) => {
        //console.log("Message Arrived", payload)
        const { data, notification } = payload;

        if (undefined !== data) {
          const { type } = data;
          const { fcmToken } = this.props.account;

          if ("ping" === type && undefined !== fcmToken) {
            this.props.sendDevicePing(fcmToken);
          }
        } else if (undefined !== notification) {
          showInformation(notification.body);
        }
      });
    }
  }

  componentDidUpdate() {
    let { needToSendToken, deviceId } = this.props.account;

    if (true !== needToSendToken) {
      return;
    }

    this.props.setNeedToSendToken(false);

    if (undefined === deviceId) {
      return;
    }

    if (!messaging) {
    } else {
      messaging
        .getToken()
        .then((token) => {
          if (token) {
            //console.log("Sending FCM Token")
            this.props.sendFCMToken(deviceId, token);
            this.props.setFCMToken(token);
          } else {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
              }
            });
          }
        })
        .catch((err) => {
          //console.log(err)
          //alert(err.toString())
          //showError(err.toString())
          showError(
            "Can not get token. Have you granted a permission for the notification?"
          );
        });
    }
  }

  render() {
    let { isAuthenticated, accessToken } = this.props.account;

    return (
      <Fragment>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-grid-beat" />
                </div>
                <h6 className="mt-3">
                  Please wait until main pages are loaded...
                </h6>
              </div>
            </div>
          }
        >
          <LoggedInRoute path="/main" component={MainPages} />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-grid-beat" />
                </div>
                <h6 className="mt-3">Please wait until my page is loaded...</h6>
              </div>
            </div>
          }
        >
          <LoggedInRoute path="/mypage" component={MyPages} />
        </Suspense>

        {/* Pages */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="line-scale-party" />
                </div>
                <h6 className="mt-3">
                  Please wait while we load all the Pages examples
                  <small>
                    Because this is a demonstration we load at once all the
                    Pages examples. This wouldn't happen in a real live app!
                  </small>
                </h6>
              </div>
            </div>
          }
        >
          <Route path="/pages" component={UserPages} />
        </Suspense>

        <Route
          exact
          path="/"
          render={() =>
            isAuthenticated ? (
              <Redirect to="/main/search" />
            ) : undefined === accessToken ? (
              <Redirect to="/pages/landing" />
            ) : (
              <h2>Loading...</h2>
            )
          }
        />
        <ToastContainer />
        {isAuthenticated ? <DeviceSelectionModal /> : <div></div>}
      </Fragment>
       
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
