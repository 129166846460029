import { getNormalizedData, mergeNormalizedData } from "../../utils/service";

const SET_GROUP_LIST = "SET_GROUP_LIST";
const ADD_OR_UPDATE_GROUP_LIST = "ADD_OR_UPDATE_GROUP_LIST";
const REMOVE_GROUP = "REMOVE_GROUP";

export const setGroupListAction = (groupList) => ({
  type: SET_GROUP_LIST,
  payload: { groupList },
});

export const addOrUpdateGroupListAction = (groupList) => ({
  type: ADD_OR_UPDATE_GROUP_LIST,
  payload: { groupList },
});

export const removeGroupAction = (groupId) => ({
  type: REMOVE_GROUP,
  payload: { groupId },
});

const initialState = {
  ids: undefined,
  byId: undefined,
};

export default function entityGroupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_LIST:
      return getNormalizedData(action.payload.groupList);
    case ADD_OR_UPDATE_GROUP_LIST:
      return mergeNormalizedData(
        state,
        getNormalizedData(action.payload.groupList)
      );
    case REMOVE_GROUP:
      state.ids = state.ids.filter((x) => x !== action.payload.groupId);
      delete state.byId[action.payload.groupId];
      return JSON.parse(JSON.stringify(state));
    default:
      return state;
  }
}
