import { getNormalizedData, mergeNormalizedData } from "../../utils/service"

const SET_DEVICE_LIST = 'SET_DEVICE_LIST'
const ADD_OR_UPDATE_DEVICE_LIST = 'ADD_OR_UPDATE_DEVICE_LIST'
const REMOVE_DEVICE = 'REMOVE_DEVICE'

export const setDeviceListAction = deviceList => ({
  type: SET_DEVICE_LIST,
  payload: { deviceList }
})

export const addOrUpdateDeviceListAction = deviceList => ({
  type: ADD_OR_UPDATE_DEVICE_LIST,
  payload: { deviceList }
})

export const removeDeviceAction = deviceId => ({
  type: REMOVE_DEVICE,
  payload: { deviceId }
})

const initialState = getNormalizedData([])

export default function entityDeviceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_LIST:
      return getNormalizedData(action.payload.deviceList)
    case ADD_OR_UPDATE_DEVICE_LIST:
      return mergeNormalizedData(state, getNormalizedData(action.payload.deviceList))
    case REMOVE_DEVICE:
      state.ids = state.ids.filter(x => x !== action.payload.deviceId)
      delete state.byId[action.payload.deviceId]
      return JSON.parse(JSON.stringify(state))
    default:
      return state
  }
}