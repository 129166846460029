import * as firebase from "firebase/app";
import "firebase/messaging";
import { firebaseConfig, VAPID } from "../config/configFirebase";

const initFirebase = () => {
  const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
  let result = initializedFirebaseApp.messaging();
  result.usePublicVapidKey(VAPID);
  return result;
};

export const messaging = !firebase.messaging.isSupported()
  ? undefined
  : initFirebase();
