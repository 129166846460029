const SET_SEARCH_TERM = "SET_SEARCH_TERM";
const SET_SEARCH_DATA = "SET_SEARCH_DATA";
const SET_MONITOR_DATA = "SET_MONITOR_DATA";
const SET_EMAIL_MODAL_VISIBLE = "SET_EMAIL_MODAL_VISIBLE";
const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS";
const SET_RECAPTCHA_STATUS = "SET_RECAPTCHA_STATUS";
const SET_SHOW_GROUP_MODAL = "SET_SHOW_GROUP_MODAL";

export const setRecaptchaStatusAction = (active) => ({
  type: SET_RECAPTCHA_STATUS,
  payload: { active },
});

export const setSearchTermAction = (term) => ({
  type: SET_SEARCH_TERM,
  payload: { term },
});

export const setSearchDataAction = (data) => ({
  type: SET_SEARCH_DATA,
  payload: { data },
});

export const setMonitorDataAction = (data) => ({
  type: SET_MONITOR_DATA,
  payload: { data },
});

export const setEmailModalVisibleAction = (visible) => ({
  type: SET_EMAIL_MODAL_VISIBLE,
  payload: { visible },
});

export const setEmailAddressAction = (addr) => ({
  type: SET_EMAIL_ADDRESS,
  payload: { addr },
});

export const setShowGroupModal = (val) => ({
  type: SET_SHOW_GROUP_MODAL,
  payload: { val },
});

const initialState = {
  searchTerm: "",
  searchData: undefined,
  emailAddress: "",
  emailModalVisible: false,
  recaptchaStatus: false,
  monitor: false,
  showGroupModal: false,
};

export default function uiSearchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECAPTCHA_STATUS:
      return {
        ...state,
        recaptchaStatus: action.payload.active,
      };
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        emailAddress: action.payload.addr,
      };
    case SET_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        emailModalVisible: action.payload.visible,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload.term,
      };
    case SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload.data,
        monitor: action.payload.data.data.monitor,
      };
    case SET_MONITOR_DATA:
      const monitor = action.payload.data.data.monitor;
      return {
        ...state,
        monitor: monitor,
        showGroupModal: monitor,
      };
    case SET_SHOW_GROUP_MODAL:
        console.log(action.payload)
      return {
        ...state,
        showGroupModal: action.payload.data,
      };
    default:
      return state;
  }
}
