export const API_ROOT = window.__BACKEND_URL__ + "/api";

const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const API_CALL_STATE = {
  UNKNOWN: 0,
  PROCESS: 1,
  SUCCESS: 2,
  FAILURE: 3,
};

const createAPI = (ID, URL, METHOD) => ({
  ID,
  URL,
  METHOD,
});

const createAPISet = (NAME, API) => {
  let result = {};
  result[`GET_${NAME}_LIST`] = createAPI(
    `GET_${NAME}_LIST`,
    `/${API}/`,
    HTTP_METHOD.GET
  );
  result[`GET_${NAME}`] = createAPI(
    `GET_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.GET
  );
  result[`CREATE_${NAME}`] = createAPI(
    `CREATE_${NAME}`,
    `/${API}/`,
    HTTP_METHOD.POST
  );
  result[`UPDATE_${NAME}`] = createAPI(
    `UPDATE_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.PUT
  );
  result[`DELETE_${NAME}`] = createAPI(
    `DELETE_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.DELETE
  );
  return result;
};

export const API_LIST = {
  ...createAPISet("DEVICE", "devices"),
  GET_USER_DATA: createAPI("GET_USER_DATA", "/account/", HTTP_METHOD.GET),
  UPDATE_USER_DATA: createAPI("UPDATE_USER_DATA", "/account/", HTTP_METHOD.PUT),
  DELETE_USER_DATA: createAPI(
    "DELETE_USER_DATA",
    "/account/",
    HTTP_METHOD.DELETE
  ),
  REGISTER: createAPI("REGISTER", "/auth/register/", HTTP_METHOD.POST),
  LOGIN: createAPI("LOGIN", "/auth/login/", HTTP_METHOD.POST),
  LOGOUT: createAPI("LOGOUT", "/auth/logout/", HTTP_METHOD.POST),
  CHANGE_PASSWORD: createAPI(
    "CHANGE_PASSWORD",
    "/auth/change_password",
    HTTP_METHOD.POST
  ),
  SEND_RECOVERY_MAIL: createAPI(
    "SEND_RECOVERY_MAIL",
    "/account/send_recovery_mail/",
    HTTP_METHOD.POST
  ),
  PASSWORD_RESET: createAPI(
    "PASSWORD_RESET",
    "/account/password_reset/",
    HTTP_METHOD.POST
  ),
  SEND_FCM_TOKEN: createAPI(
    "SEND_FCM_TOKEN",
    "/set_device_token/",
    HTTP_METHOD.POST
  ),
  SEND_DEVICE_PING: createAPI(
    "SEND_DEVICE_PING",
    "/ping_device/",
    HTTP_METHOD.POST
  ),
  GET_IP_CHANGE_HISTORY: createAPI(
    "GET_IP_CHANGE_HISTORY",
    "/ip_change_history/",
    HTTP_METHOD.POST
  ),
  SEARCH_RECORD: createAPI(
    "SEARCH_RECORD",
    "/search_record/",
    HTTP_METHOD.POST
  ),
  TOGGLE_SEARCH_RECORD_MONITORING: createAPI(
    "MONITOR_RECORD",
    "/monitor_search_record/",
    HTTP_METHOD.POST
  ),
  SEND_USER_EMAIL: createAPI(
    "SEND_USER_EMAIL",
    "/send_email/",
    HTTP_METHOD.POST
  ),
  CREATE_GROUP: createAPI("CREATE_GROUP", "/groups/", HTTP_METHOD.POST),
  GET_GROUPS: createAPI("GET_GROUPS", "/groups/", HTTP_METHOD.GET),
  DELETE_GROUP: createAPI("DELETE_GROUP", "/groups/{0}/", HTTP_METHOD.DELETE),
  GET_MONITOR_GROUPS: createAPI(
    "GET_MONITOR_GROUPS",
    "/monitor/groups/",
    HTTP_METHOD.GET
  ),
  UPDATE_MONITOR_GROUP: createAPI(
    "UPDATE_MONITOR_GROUP",
    "/monitor/groups/{0}/",
    HTTP_METHOD.PUT
  ),
  GET_SEARCH_HISTORY: createAPI(
    "GET_SEARCH_HISTORY",
    "/search_history/",
    HTTP_METHOD.GET
  ),
  CLEAR_SEARCH_HISTORY: createAPI(
    "CLEAR_SEARCH_HISTORY",
    "/search_history/",
    HTTP_METHOD.DELETE
  ),
};
