import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rodal from 'rodal';
import { ModalHeader, ModalBody, FormGroup, Label, ModalFooter, Button, Input, CustomInput } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { faMobileAlt, faTabletAlt, faDesktop, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiLogoutUserAction, apiRegisterDeviceAction } from '../../../sagas/apiCallSaga';
import { DeviceType } from '../../../utils/const';
import { setNewDeviceFieldAction } from '../../../reducers/ui/device';
import { setDeviceIdAction, setNeedToSendTokenAction } from '../../../reducers/entity/account';
import Loader from 'react-loaders';
import { checkUIBlock, showWarning } from '../../../utils/service';
import { API_LIST } from '../../../utils/api';
import BlockUi from 'react-block-ui';

const mapStateToProps = store => ({
  state: store.ui.deviceSelection,
  account: store.entity.account,
  devices: store.entity.devices,
  apiCallData: store.apiCallData,
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setField: setNewDeviceFieldAction,
    logout: apiLogoutUserAction,
    setDevice: setDeviceIdAction,
    registerDevice: apiRegisterDeviceAction,
    setNeedToSendToken: setNeedToSendTokenAction,
  }, dispatch)
)

class DeviceSelectionModal extends Component {

  btnLogoutClicked = () => {
    this.props.logout()
  }

  btnSelectDeviceClicked = deviceId => {
    this.props.setDevice(deviceId)
    this.props.setNeedToSendToken(true)
  }

  btnRegisterClicked = () => {
    let { newDevice } = this.props.state
    let devicesById = this.props.devices.byId

    if ("" === newDevice.name) {
      showWarning("Device name is requred")
      return
    }

    if (0 <= Object.values(devicesById).map(x => x.name).indexOf(newDevice.name)) {
      showWarning("Device name already exists. Please try with another name.")
      return
    }

    this.props.registerDevice(newDevice)
  }

  getIcon = type => {
    switch (type) {
      case DeviceType.DESKTOP:
        return faDesktop
      case DeviceType.MOBILE:
        return faMobileAlt
      case DeviceType.TABLET:
        return faTabletAlt
      default:
        return faDesktop
    }
  }

  render() {
    let { deviceId } = this.props.account
    let deviceList = Object.values(this.props.devices.byId)
    let { newDevice } = this.props.state
    let { setField, apiCallData } = this.props

    return (
      <Rodal visible={undefined === deviceId}
        animation={"flip"}
        showMask={true}
        onClose={this.btnLogoutClicked}
      >
        <ModalHeader>Select Device</ModalHeader>
        <BlockUi
            tag="div"
            blocking={
                checkUIBlock(apiCallData[API_LIST.GET_DEVICE_LIST.ID])
            }
            className="block-overlay-dark"
            loader={<Loader color="#ffffff" active type={"ball-spin-fade-loader"}/>}
        >
          <ModalBody className="">
            <h5>My devices</h5>
            <div className="pl-3">
              {
                deviceList.map(deviceInfo => (
                  <div className="d-flex align-items-center justify-content-between mb-3" key={deviceInfo.id}>
                    <div style={{width: 60}} className="text-center">
                      <FontAwesomeIcon icon={this.getIcon(deviceInfo.type)} size="3x"/>
                    </div>
                    <strong>{deviceInfo.name}</strong>
                    <Button className="btn-dashed" outline onClick={() => {this.btnSelectDeviceClicked(deviceInfo.id)}}>Select</Button>
                  </div>
                ))
              }
            </div>
            <h5>Register a new device</h5>
            <FormGroup>
              <Label>Device Type*</Label>
              <CustomInput
                type="select"
                id="device_type_select"
                defaultValue={newDevice.type}
                onChange={e => setField("type", parseInt(e.target.value))}
              >
                <option value={DeviceType.DESKTOP}>
                  Desktop
                </option>
                <option value={DeviceType.TABLET}>
                  Tablet
                </option>
                <option value={DeviceType.MOBILE}>
                  Mobile
                </option>
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label>Device Name*</Label>
              <Input
                type="text"
                placeholder="Device name here..."
                value={newDevice.name}
                onChange={e => setField("name", e.target.value)}
              />
            </FormGroup>
            <div className="pull-right mb-3">
              <Button onClick={this.btnRegisterClicked}>Register</Button>
            </div>
          </ModalBody>
        </BlockUi>
      </Rodal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSelectionModal);