
const SET_IP_CHANGE_HISTORY_DATA = 'SET_IP_CHANGE_HISTORY_DATA'

export const setIpChangeHistoryDataAction = data => ({
  type: SET_IP_CHANGE_HISTORY_DATA,
  payload: { data }
})

const initialState = {
  data: undefined,
}

export default function entityIpHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IP_CHANGE_HISTORY_DATA:
      return {
        ...state,
        data: action.payload.data
      }
    default:
      return state
  }
}