import { DeviceType } from "../../utils/const"

const SET_NEW_DEVICE_FIELD = 'SET_NEW_DEVICE_FIELD'

export const setNewDeviceFieldAction = (field, value) => ({
  type: SET_NEW_DEVICE_FIELD,
  payload: { field, value }
})

const initialState = {
  newDevice: {
    type: DeviceType.DESKTOP,
    name: "",
  }
}

export default function uiDeviceSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_DEVICE_FIELD:
      let { newDevice } = state
      newDevice[action.payload.field] = action.payload.value
      return {
        ...state,
        newDevice
      }
    default:
      return state
  }
}