import axios from "axios";

const API_ROOT = window.__BACKEND_URL__ + "/api/";

const UPDATE_COOKIE_SETTINGS = API_ROOT + "cookie_settings/update_cookie/";

export const dbUpdateCookieSettings = async (cookieValue) => {
  let putData = {};

  console.log({ cookieValue });

  if (cookieValue === "allowed_all") {
    putData["allowed_all"] = true;
  }

  if (cookieValue == "allowed_necessary") {
    putData["allowed_necessary"] = true;
  }

  return axios.patch(UPDATE_COOKIE_SETTINGS, putData);
};
