export const DeviceType = {
  DESKTOP: 1,
  MOBILE: 2,
  TABLET: 3,
};

export const IpHistoryViewMode = {
  VIEW_ALL: 0,
  ONLY_MY_DEVICE: 1,
};

export const SearchDataType = {
  IP: 1,
  DOMAIN: 2,
  ASN: 3,
  PHONE_NUMBER: 4,
};

export const ReverseSearchDataType = {
  1: "IP",
  2: "DOMAIN",
  3: "ASN",
  4: "Phone Number",
};

export const PhoneNumberRoamingStatus = {
  UNKNOWN: "unknown",
  ROAMING: "roaming",
  NOT_ROAMING: "not_roaming",
};
