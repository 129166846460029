import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineReducers } from "redux";
import apiCallReducer from "./apiCall";
import appDataReducer from "./appData";
import entityAccountReducer from "./entity/account";
import entityDeviceReducer from "./entity/device";
import entityGroupReducer from "./entity/group";
import entityIpHistoryReducer from "./entity/ipHistory";
import entityMonitorReducer from "./entity/monitor";
import entitySearchHistoryReducer from "./entity/searchHistory";
import ThemeOptions from "./ThemeOptions";
import uiAccountReducer from "./ui/account";
import uiDeviceSelectionReducer from "./ui/device";
import uiForgotPasswordReducer from "./ui/forgotPassword";
import uiIpHistoryReducer from "./ui/ipHistory";
import uiLoginReducer from "./ui/login";
import uiMonitorReducer from "./ui/monitor";
import uiPasswordResetReducer from "./ui/passwordReset";
import uiRegisterReducer from "./ui/register";
import uiSearchReducer from "./ui/search";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ThemeOptions,
  router: connectRouter(history),
  apiCallData: apiCallReducer,
  appData: appDataReducer,
  entity: combineReducers({
    account: entityAccountReducer,
    devices: entityDeviceReducer,
    ipHistory: entityIpHistoryReducer,
    searchHistory: entitySearchHistoryReducer,
    monitor: entityMonitorReducer,
    group: entityGroupReducer,
  }),
  ui: combineReducers({
    login: uiLoginReducer,
    register: uiRegisterReducer,
    account: uiAccountReducer,
    passwordReset: uiPasswordResetReducer,
    forgotPassword: uiForgotPasswordReducer,
    deviceSelection: uiDeviceSelectionReducer,
    ipHistory: uiIpHistoryReducer,
    search: uiSearchReducer,
    monitor: uiMonitorReducer,
  }),
});

export default rootReducer;
