const SET_MONITOR_LIST = "SET_MONITOR_LIST";
const ADD_OR_UPDATE_MONITOR_LIST = "ADD_OR_UPDATE_MONITOR_LIST";
const REMOVE_MONITOR = "REMOVE_MONITOR";

export const setMonitorListAction = (monitorList) => ({
  type: SET_MONITOR_LIST,
  payload: { monitorList },
});

export const addOrUpdateMonitorListAction = (monitorData) => ({
  type: ADD_OR_UPDATE_MONITOR_LIST,
  payload: { monitorData },
});

export const removeMonitorAction = (monitorId) => ({
  type: REMOVE_MONITOR,
  payload: { monitorId },
});

const initialState = {
  data: undefined,
};

export default function entityMonitorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MONITOR_LIST:
      return {
        ...state,
        data: action.payload.monitorList.data,
      };
    case ADD_OR_UPDATE_MONITOR_LIST:
      const _data = [...state.data];
      for (let i = 0; i < _data.length; i++) {
        if (_data[i].id === action.payload.monitorData.id) {
          _data[i] = action.payload.monitorData;
        }
      }
      return {
        ...state,
        data: _data,
      };
    case REMOVE_MONITOR:
      state.ids = state.ids.filter((x) => x !== action.payload.monitorId);
      delete state.byId[action.payload.monitorId];
      return JSON.parse(JSON.stringify(state));
    default:
      return state;
  }
}
