import axios from "axios";
import HttpStatus from "http-status-codes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apiCallFinishAction, apiCallStartAction } from "../reducers/apiCall";
import {
  accountLoginAction,
  accountLogoutAction,
  accountRefreshByAccessTokenAction,
  accountUpdateDataAction,
  setDeviceIdAction,
  setNeedToSendTokenAction,
} from "../reducers/entity/account";
import {
  addOrUpdateDeviceListAction,
  removeDeviceAction,
  setDeviceListAction,
} from "../reducers/entity/device";
import {
  addOrUpdateGroupListAction,
  removeGroupAction,
  setGroupListAction,
} from "../reducers/entity/group";
import { setIpChangeHistoryDataAction } from "../reducers/entity/ipHistory";
import {
  addOrUpdateMonitorListAction,
  setMonitorListAction,
} from "../reducers/entity/monitor";
import { setSearchHistoryDataAction } from "../reducers/entity/searchHistory";
import {
  closeAccountDeleteConfirmAction,
  closeEditProfileModalAction,
  closePasswordChangeModalAction,
} from "../reducers/ui/account";
import {
  setMonitorDataAction,
  setSearchDataAction,
} from "../reducers/ui/search";
import { API_LIST } from "../utils/api";
import {
  generateApiUrl,
  history,
  showError,
  showSuccess,
} from "../utils/service";

function* callAPI(api, apiCallParams = {}, apiURLParams) {
  yield put(apiCallStartAction(api.ID));

  let result = {
    statusCode: 0,
    data: "",
  };

  try {
    const apiUrl = generateApiUrl(api.URL, apiURLParams);
    const { data } = yield call(axios, {
      method: api.METHOD,
      url: apiUrl,
      data: apiCallParams,
      params: api.METHOD === "GET" ? apiCallParams : undefined,
    });
    result.statusCode = HttpStatus.OK;
    result.data = data;
  } catch (err) {
    const { response } = err;

    let errMsg = "";

    if (undefined === response) {
      result.statusCode = HttpStatus.SERVICE_UNAVAILABLE;
      result.message = "Server is not available";

      errMsg = result.message;

      yield put(accountLogoutAction());
    } else {
      result.statusCode = response.status;
      result.data = response.data;

      switch (response.status) {
        case HttpStatus.UNAUTHORIZED:
          errMsg = "Session is expired.\nPlease login.";
          yield put(accountLogoutAction());
          break;
        case HttpStatus.INTERNAL_SERVER_ERROR:
          errMsg =
            "Error happened on the server.\nPlease contact administrator.";
          break;
        case HttpStatus.NOT_FOUND:
          errMsg = "Not found";
          break;
        case HttpStatus.BAD_REQUEST:
          errMsg = response.data.message;
          break;
      }
    }

    if ("" !== errMsg && API_LIST.LOGOUT.ID !== api.ID) {
      showError(errMsg);
    }
  }

  yield put(apiCallFinishAction(api.ID, result.statusCode));
  return result;
}

function* loadInitialAppData() {
  yield put(apiLoadDeviceAction());
}

// User Login
export const apiLoginUserAction = (loginCredential) => ({
  type: API_LIST.LOGIN.ID,
  payload: { loginCredential },
});
function* apiLoginUserEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.LOGIN,
    action.payload.loginCredential
  );

  if (HttpStatus.OK === statusCode) {
    yield put(accountLoginAction(data.token, data.user));
    yield loadInitialAppData();
    history.push("/");
  }
}

// User Registration
export const apiRegisterUserAction = (registerInfo) => ({
  type: API_LIST.REGISTER.ID,
  payload: {
    registerInfo,
  },
});
function* apiRegisterUserEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.REGISTER,
    action.payload.registerInfo
  );

  if (HttpStatus.OK === statusCode) {
    yield put(accountLoginAction(data.token, data.user));
    loadInitialAppData();
    history.push("/");
  }
}

// User Logout
export const apiLogoutUserAction = () => ({
  type: API_LIST.LOGOUT.ID,
});
function* apiLogoutUserEffect() {
  yield callAPI(API_LIST.LOGOUT);
  yield put(accountLogoutAction());

  history.push("/");
}

// Change Password
export const apiChangePasswordAction = (oldPassword, newPassword) => ({
  type: API_LIST.CHANGE_PASSWORD.ID,
  payload: { oldPassword, newPassword },
});
function* apiChangePasswordEffect(action) {
  let { statusCode } = yield callAPI(API_LIST.CHANGE_PASSWORD, {
    oldPassword: action.payload.oldPassword,
    newPassword: action.payload.newPassword,
  });

  if (HttpStatus.OK === statusCode) {
    showSuccess("Password is successfully changed.");
    yield put(closePasswordChangeModalAction());
  }
}

// Update User Data
export const apiUpdateUserDataAction = (userData) => ({
  type: API_LIST.UPDATE_USER_DATA.ID,
  payload: { userData },
});
function* apiUpdateUserDataEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.UPDATE_USER_DATA,
    action.payload.userData
  );
  if (HttpStatus.OK === statusCode) {
    showSuccess("Successfully updated profile information.");
    yield put(accountUpdateDataAction(data));
    yield put(closeEditProfileModalAction());
  }
}

// Delete User Data
export const apiDeleteUserDataAction = () => ({
  type: API_LIST.DELETE_USER_DATA.ID,
});
function* apiDeleteUserDataEffect() {
  let { statusCode } = yield callAPI(API_LIST.DELETE_USER_DATA);

  if (HttpStatus.OK === statusCode) {
    yield put(closeAccountDeleteConfirmAction());
    yield put(accountLogoutAction());
    history.push("/");
  }
}

// Send Password Recovery Mail
export const apiSendPasswordRecoveryMailAction = (email) => ({
  type: API_LIST.SEND_RECOVERY_MAIL.ID,
  payload: { email },
});
function* apiSendPasswordRecoveryMailEffect(action) {
  const { statusCode } = yield callAPI(
    API_LIST.SEND_RECOVERY_MAIL,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    showSuccess("recovery mail was sent.");
  }
}

// Reset Password
export const apiPasswordResetAction = (token, password) => ({
  type: API_LIST.PASSWORD_RESET.ID,
  payload: { token, password },
});
function* apiPasswordResetEffect(action) {
  const { statusCode } = yield callAPI(API_LIST.PASSWORD_RESET, action.payload);

  if (HttpStatus.OK === statusCode) {
    history.push("/pages/login-boxed");
  }
}

// Get User Device List
export const apiLoadDeviceAction = () => ({
  type: API_LIST.GET_DEVICE_LIST.ID,
});
function* apiLoadDeviceEffect() {
  let { data, statusCode } = yield callAPI(API_LIST.GET_DEVICE_LIST);

  if (HttpStatus.OK === statusCode) {
    yield put(setDeviceListAction(data));
  }
}

// Register a new device
export const apiRegisterDeviceAction = (deviceInfo) => ({
  type: API_LIST.CREATE_DEVICE.ID,
  payload: { deviceInfo },
});
function* apiRegisterDeviceEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.CREATE_DEVICE,
    action.payload.deviceInfo
  );

  if (HttpStatus.OK === statusCode) {
    yield put(addOrUpdateDeviceListAction([data]));
    yield put(setDeviceIdAction(data.id));
    yield put(setNeedToSendTokenAction(true));
  }
}

// Remove a device
export const apiDeleteDeviceAction = (deviceId) => ({
  type: API_LIST.DELETE_DEVICE.ID,
  payload: { deviceId },
});
function* apiDeleteDeviceEffect(action) {
  let { statusCode } = yield callAPI(
    API_LIST.DELETE_DEVICE,
    {},
    action.payload.deviceId
  );

  if (HttpStatus.OK === statusCode) {
    yield put(removeDeviceAction(action.payload.deviceId));
  }
}

// Send FCM Token
export const apiSendFCMTokenAction = (deviceId, token) => ({
  type: API_LIST.SEND_FCM_TOKEN.ID,
  payload: { deviceId, token },
});
function* apiSendFCMTokenEffect(action) {
  yield callAPI(API_LIST.SEND_FCM_TOKEN, {
    device_id: action.payload.deviceId,
    device_token: action.payload.token,
  });
}

// Send Device Ping
export const apiSendDevicePingAction = (token) => ({
  type: API_LIST.SEND_DEVICE_PING.ID,
  payload: { token },
});
function* apiSendDevicePingEffect(action) {
  yield callAPI(API_LIST.SEND_DEVICE_PING, {
    token: action.payload.token,
  });
}

// Get Device IP Change History
export const apiGetDeviceIPChangeHistoryAction = () => ({
  type: API_LIST.GET_IP_CHANGE_HISTORY.ID,
});
function* apiGetDeviceIPChangeHistoryEffect(action) {
  let { data, statusCode } = yield callAPI(API_LIST.GET_IP_CHANGE_HISTORY);
  if (HttpStatus.OK === statusCode) {
    yield put(setIpChangeHistoryDataAction(data));
  }
}

export const apiGetSearchHistoryAction = () => ({
  type: API_LIST.GET_SEARCH_HISTORY.ID,
});
function* apiGetSearchHistoryEffect(action) {
  let { data, statusCode } = yield callAPI(API_LIST.GET_SEARCH_HISTORY);
  if (HttpStatus.OK === statusCode) {
    yield put(setSearchHistoryDataAction(data));
  }
}

export const apiDeleteSearchHistoryAction = () => ({
  type: API_LIST.CLEAR_SEARCH_HISTORY.ID,
});
function* apiDeleteSearchHistoryEffect(action) {
  let { statusCode } = yield callAPI(API_LIST.CLEAR_SEARCH_HISTORY);
  if (HttpStatus.OK === statusCode) {
    yield put(setSearchHistoryDataAction([]));
  }
}

// Search Record
export const apiSearchRecordAction = (term, captcha_token) => ({
  type: API_LIST.SEARCH_RECORD.ID,
  payload: { term, captcha_token },
});
function* apiSearchRecordEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.SEARCH_RECORD,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setSearchDataAction(data));
  }
}

export const apiMonitorSearchRecordToggleAction = (id) => ({
  type: API_LIST.TOGGLE_SEARCH_RECORD_MONITORING.ID,
  payload: { id },
});
function* apiMonitorSearchRecordEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.TOGGLE_SEARCH_RECORD_MONITORING,
    action.payload
  );

  if (HttpStatus.OK === statusCode) {
    yield put(setMonitorDataAction(data));
  }
}

export const apiAddGroupAction = (name) => ({
  type: API_LIST.CREATE_GROUP.ID,
  payload: { name },
});
function* apiAddGroupEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.CREATE_GROUP,
    action.payload
  );
  if (HttpStatus.OK === statusCode) {
    yield put(addOrUpdateGroupListAction([data.data]));
  }
}

export const apiDeleteGroupAction = (id) => ({
  type: API_LIST.DELETE_GROUP.ID,
  payload: { id },
});
function* apiDeleteGroupEffect(action) {
  let { statusCode } = yield callAPI(API_LIST.DELETE_GROUP, action.payload, [
    action.payload.id,
  ]);
  if (HttpStatus.OK === statusCode) {
    yield put(removeGroupAction(action.payload.id));
  }
}

export const apiGetGroupsAction = () => ({
  type: API_LIST.GET_GROUPS.ID,
  payload: {},
});
function* apiGetGroupsEffect(action) {
  let { data, statusCode } = yield callAPI(API_LIST.GET_GROUPS, action.payload);
  if (HttpStatus.OK === statusCode) {
    yield put(setGroupListAction(data.data));
  }
}

export const apiUpdateGroupMonitorAction = (id, group_id) => ({
  type: API_LIST.UPDATE_MONITOR_GROUP.ID,
  payload: { id, group_id },
});
function* apiUpdateGroupMonitorEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.UPDATE_MONITOR_GROUP,
    action.payload,
    [action.payload.id]
  );
  if (HttpStatus.OK === statusCode) {
    yield put(addOrUpdateMonitorListAction(data.data));
  }
}

export const apiListGroupMonitorsAction = (group_id) => ({
  type: API_LIST.GET_MONITOR_GROUPS.ID,
  payload: { group_id },
});
function* apiListGroupMonitorsEffect(action) {
  let { data, statusCode } = yield callAPI(
    API_LIST.GET_MONITOR_GROUPS,
    action.payload
  );
  if (HttpStatus.OK === statusCode) {
    yield put(setMonitorListAction(data));
  }
}

export const apiSendUserEmailAction = (email, content, callback) => ({
  type: API_LIST.SEND_USER_EMAIL.ID,
  payload: { email, content, callback },
});
function* apiSendUserEmailEffect(action) {
  let { statusCode } = yield callAPI(API_LIST.SEND_USER_EMAIL, {
    email: action.payload.email,
    content: action.payload.content,
  });

  if (HttpStatus.OK === statusCode) {
    yield call(action.payload.callback);
  }
}

// Load App Data
const API_LOAD_APP_DATA = "API_LOAD_APP_DATA";
export const apiLoadAppDataAction = () => ({
  type: API_LOAD_APP_DATA,
});
function* apiLoadAppDataEffect() {
  let { data, statusCode } = yield callAPI(API_LIST.GET_USER_DATA);

  if (HttpStatus.OK === statusCode) {
    yield put(accountRefreshByAccessTokenAction(data));
    yield loadInitialAppData();
  }
}

export default function* apiCallSaga() {
  yield all([
    takeLatest(API_LOAD_APP_DATA, apiLoadAppDataEffect),

    takeLatest(API_LIST.REGISTER.ID, apiRegisterUserEffect),
    takeLatest(API_LIST.LOGIN.ID, apiLoginUserEffect),
    takeLatest(API_LIST.LOGOUT.ID, apiLogoutUserEffect),
    takeLatest(API_LIST.CHANGE_PASSWORD.ID, apiChangePasswordEffect),
    takeLatest(API_LIST.UPDATE_USER_DATA.ID, apiUpdateUserDataEffect),
    takeLatest(API_LIST.DELETE_USER_DATA.ID, apiDeleteUserDataEffect),
    takeLatest(
      API_LIST.SEND_RECOVERY_MAIL.ID,
      apiSendPasswordRecoveryMailEffect
    ),
    takeLatest(API_LIST.PASSWORD_RESET.ID, apiPasswordResetEffect),

    takeLatest(API_LIST.GET_DEVICE_LIST.ID, apiLoadDeviceEffect),
    takeLatest(API_LIST.CREATE_DEVICE.ID, apiRegisterDeviceEffect),
    takeLatest(API_LIST.DELETE_DEVICE.ID, apiDeleteDeviceEffect),

    takeLatest(
      API_LIST.GET_IP_CHANGE_HISTORY.ID,
      apiGetDeviceIPChangeHistoryEffect
    ),
    takeLatest(API_LIST.SEND_DEVICE_PING.ID, apiSendDevicePingEffect),
    takeLatest(API_LIST.SEND_FCM_TOKEN.ID, apiSendFCMTokenEffect),

    takeLatest(API_LIST.SEARCH_RECORD.ID, apiSearchRecordEffect),
    takeLatest(API_LIST.SEND_USER_EMAIL.ID, apiSendUserEmailEffect),
    takeLatest(
      API_LIST.TOGGLE_SEARCH_RECORD_MONITORING.ID,
      apiMonitorSearchRecordEffect
    ),
    takeLatest(API_LIST.GET_MONITOR_GROUPS.ID, apiListGroupMonitorsEffect),
    takeLatest(API_LIST.UPDATE_MONITOR_GROUP.ID, apiUpdateGroupMonitorEffect),
    takeLatest(API_LIST.GET_GROUPS.ID, apiGetGroupsEffect),
    takeLatest(API_LIST.CREATE_GROUP.ID, apiAddGroupEffect),
    takeLatest(API_LIST.DELETE_GROUP.ID, apiDeleteGroupEffect),

    takeLatest(API_LIST.GET_SEARCH_HISTORY.ID, apiGetSearchHistoryEffect),
    takeLatest(API_LIST.CLEAR_SEARCH_HISTORY.ID, apiDeleteSearchHistoryEffect),
  ]);
}
