import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

//import * as serviceWorker from './serviceWorker';

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import { store } from "./config/configureStore";
import { Provider } from "react-redux";
import CookieBanner from "DemoPages/CookieBanner";
//import { showError, isFCMSupported } from "./utils/service";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then((registration) => {
      //console.log("Registration Successful. Scope: ", registration.scope)
    })
    .catch((err) => {
      //console.log("Service worker registration failed. Error:", err)
    });
}

const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
        <CookieBanner />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./DemoPages/Main', () => {
//         const NextApp = require('./DemoPages/Main').default;
//         renderApp(NextApp);
//     });
// }
//serviceWorker.unregister();
