// Test

/*export const firebaseConfig = {
  apiKey: "AIzaSyD3ggCNJeG7SbGHP2QSzJSn7Z5Jq7c-Sl8",
  authDomain: "fcm-test-5d8d3.firebaseapp.com",
  databaseURL: "https://fcm-test-5d8d3.firebaseio.com",
  projectId: "fcm-test-5d8d3",
  storageBucket: "fcm-test-5d8d3.appspot.com",
  messagingSenderId: "760908208551",
  appId: "1:760908208551:web:9cd68d7a63a6e12c0d3e82",
  measurementId: "G-MFVQM8THSE"
};

export const VAPID = "BL13E0d0dmp2CFysnCU9SR3uWy40sCnqanEt_eZf_GoLUA-EV1M9-naGw9Gu9gKraHm6d1c5yuYRfpAaysoitJM";*/

// Deploy

export const firebaseConfig = {
  apiKey: "AIzaSyBQzMTmffy2Qhm222FOx8S5raLZApSA_bA",
  authDomain: "cyberspf.firebaseapp.com",
  databaseURL: "https://cyberspf.firebaseio.com",
  projectId: "cyberspf",
  storageBucket: "cyberspf.appspot.com",
  messagingSenderId: "550604375308",
  appId: "1:550604375308:web:a300731ae34910d45ab529",
  measurementId: "G-8YGTL4BSDC"
};

export const VAPID = "BGjN0e6QboxOcN76LLfeSds1Hw97XiRquqVxjFd0fne8j9QhggK7yGxF_Zop6cNodoYDYARSxvFgia6ymXcP3lo";