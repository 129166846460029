import { API_ROOT, API_CALL_STATE } from "./api";
import { toast, Bounce, ToastPosition, ToastType } from "react-toastify";
import {
  isIOS,
  isIOS13,
  isMobileSafari,
  isEdge,
  isChrome,
  isFirefox,
} from "react-device-detect";

export const history = require("history").createHashHistory();

export const isAppleDevice = () => {
  return isIOS || isIOS13 || isMobileSafari;
};

export const isFCMSupported = () => {
  return !isAppleDevice() && (isEdge || isChrome || isFirefox);
};

export const getPortName = (port) => {
  switch (port) {
    case 20:
    case 21:
      return "File Transfer Protocol (FTP)";
    case 22:
      return "Secure Shell (SSH)";
    case 23:
      return "Telnet";
    case 25:
      return "Simple Mail Transfer Protocol (SMTP)";
    case 50:
    case 51:
      return "IPSec";
    case 53:
      return "Domain Name System (DNS)";
    case 67:
    case 68:
      return "Dynamic Host Configuration Protocol (DHCP)";
    case 69:
      return "Trivial File Transfer Protocol (TFTP)";
    case 80:
      return "HyperText Transfer Protocol (HTTP)";
    case 110:
      return "Post Office Protocol (POP3)";
    case 119:
      return "Network News Transport Protocol (NNTP)";
    case 123:
      return "Network Time Protocol (NTP)";
    case 135:
    case 136:
    case 137:
    case 138:
    case 139:
      return "NetBIOS";
    case 143:
      return "Internet Message Access Protocol (IMAP4)";
    case 161:
    case 162:
      return "Simple Network Management Protocol (SNMP)";
    case 389:
      return "Lightweight Directory Access Protocol";
    case 443:
      return "HTTP with Secure Sockets Layer (SSL)";
    case 465:
      return "Simple Mail Transfer Protocol over Secure Sockets Layer (SMTPS)";
    case 993:
      return "Internet Message Access Protocol over Secure Sockets Layer (IMAPS)";
    case 3389:
      return "Remote Desktop Protocol";
    default:
      return "Unknown";
  }
};

export const generateApiUrl = (url, urlParams) => {
  if (!Array.isArray(urlParams)) {
    urlParams = [urlParams];
  }

  for (let i = 0; i < urlParams.length; ++i) {
    url = url.replace(`{${i}}`, urlParams[i]);
  }

  return API_ROOT + url;
};

export const getURLParameters = (url) => {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const getNormalizedData = (list) => {
  let result = {
    ids: [],
    byId: {},
  };

  if (true !== Array.isArray(list)) return result;

  list.forEach((item) => {
    if (undefined !== item.id) {
      result.ids.push(item.id);
      result.byId[item.id] = item;
    }
  });

  return result;
};

export const checkUIBlock = (apiState) => {
  if (undefined === apiState) return false;

  if (API_CALL_STATE.PROCESS === apiState.state) return true;

  return false;
};

export const mergeNormalizedData = (data1, data2) => {
  let result = JSON.parse(JSON.stringify(data1));
  data2.ids.forEach((id) => {
    if (result.ids.indexOf(id) < 0) result.ids.push(id);

    result.byId[id] = data2.byId[id];
  });
  return result;
};

export const isEmptyString = (str) => {
  return undefined === str || "" === str.toString();
};

export const hasEmptyValue = (arr) => {
  if (!Array.isArray(arr)) return false;

  let result = false;

  arr.forEach((x) => {
    if (isEmptyString(x)) {
      result = true;
    }
  });
  return result;
};

const showToast = (type, msg) => {
  toast(msg, {
    transition: Bounce,
    closeButton: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: 5000,
    position: ToastPosition.BOTTOM_CENTER,
    type: type,
  });
};

export const showError = (msg) => showToast(ToastType.ERROR, msg);
export const showSuccess = (msg) => showToast(ToastType.SUCCESS, msg);
export const showWarning = (msg) => showToast(ToastType.WARNING, msg);
export const showInformation = (msg) => showToast(ToastType.INFO, msg);
