
const OPEN_PASSWORD_CHANGE_MODAL = 'OPEN_PASSWORD_CHANGE_MODAL'
const CLOSE_PASSWORD_CHANGE_MODAL = 'CLOSE_PASSWORD_CHANGE_MODEL'
const SET_PASSWORD_CHANGE_MODAL_FIELD = 'SET_PASSWORD_CHANGE_MODAL_FIELD'

const OPEN_EDIT_PROFILE_MODAL = 'OPEN_EDIT_PROFILE_MODAL'
const CLOSE_EDIT_PROFILE_MODAL = 'CLOSE_EDIT_PROFILE_MODAL'
const SET_EDIT_PROFILE_DATA = 'SET_EDIT_PROFILE_DATA'
const SET_EDIT_PROFILE_FIELD = 'SET_EDIT_PROFILE_FIELD'

const OPEN_ACCOUNT_DELETE_CONFIRM = 'OPEN_ACCOUNT_DELETE_CONFIRM'
const CLOSE_ACCOUNT_DELETE_CONFIRM = 'CLOSE_ACCOUNT_DELETE_CONFIRM'

export const openPasswordChangeModalAction = () => ({
  type: OPEN_PASSWORD_CHANGE_MODAL,
})

export const closePasswordChangeModalAction = () => ({
  type: CLOSE_PASSWORD_CHANGE_MODAL,
})

export const setPasswordChangeModalFieldAction = (field, value) => ({
  type: SET_PASSWORD_CHANGE_MODAL_FIELD,
  payload: { field, value }
})

export const setEditProfileDataAction = profileData => ({
  type: SET_EDIT_PROFILE_DATA,
  payload: { profileData }
})

export const setEditProfileFieldAction = (field, value) => ({
  type: SET_EDIT_PROFILE_FIELD,
  payload: { field, value }
})

export const openEditProfileModalAction = () => ({
  type: OPEN_EDIT_PROFILE_MODAL,
})

export const closeEditProfileModalAction = () => ({
  type: CLOSE_EDIT_PROFILE_MODAL,
})

export const openAccountDeleteConfirmAction = () => ({
  type: OPEN_ACCOUNT_DELETE_CONFIRM,
})

export const closeAccountDeleteConfirmAction = () => ({
  type: CLOSE_ACCOUNT_DELETE_CONFIRM,
})

const initialState = {
  passwordChangeModal: {
    visible: false,
    oldPassword: "",
    newPassword: "",
    newPasswordRe: "",
  },
  editProfileModal: {
    visible: false,
  },
  editProfile: {},
  deleteAccountConfirmVisible: false,
}

export default function uiAccountReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_PASSWORD_CHANGE_MODAL: {
      let { passwordChangeModal } = state
      passwordChangeModal.visible = true
      return {
        ...state,
        passwordChangeModal,
      }
    }
    case CLOSE_PASSWORD_CHANGE_MODAL: {
      let { passwordChangeModal } = state
      passwordChangeModal.visible = false
      return {
        ...state,
        passwordChangeModal,
      }
    }
    case SET_PASSWORD_CHANGE_MODAL_FIELD: {
      let { passwordChangeModal } = state
      passwordChangeModal[action.payload.field] = action.payload.value
      return {
        ...state,
        passwordChangeModal,
      }
    }
    case SET_EDIT_PROFILE_DATA: 
      return {
        ...state,
        editProfile: action.payload.profileData,
      }
    case SET_EDIT_PROFILE_FIELD: {
      let { editProfile } = state
      editProfile[action.payload.field] = action.payload.value
      return {
        ...state,
        editProfile,
      }
    }
    case OPEN_EDIT_PROFILE_MODAL: {
      let { editProfileModal } = state
      editProfileModal.visible = true
      return {
        ...state,
        editProfileModal
      }
    }
    case CLOSE_EDIT_PROFILE_MODAL: {
      let { editProfileModal } = state
      editProfileModal.visible = false
      return {
        ...state,
        editProfileModal
      }
    }
    case OPEN_ACCOUNT_DELETE_CONFIRM:
      return {
        ...state,
        deleteAccountConfirmVisible: true,
      }
    case CLOSE_ACCOUNT_DELETE_CONFIRM:
      return {
        ...state,
        deleteAccountConfirmVisible: false,
      }
    default:
      return state
  }
}