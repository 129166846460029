

const initialState = {
}

export default function appDataReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}