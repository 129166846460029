
const SET_REGISTER_FIELD = 'SET_REGISTER_FIELD'

export const setRegisterFieldAction = (field, value) => ({
  type: SET_REGISTER_FIELD,
  payload: {
    field,
    value,
  }
})

const initialState = {
  registerInfo: {
    /*email: "joecristop929@gmail.com",
    first_name: "Joe",
    last_name: "Cristop",
    password: "password",
    password_re: "password",*/
  }
}

export default function uiRegisterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REGISTER_FIELD: {
      let { registerInfo } = state
      registerInfo[action.payload.field] = action.payload.value
      return {
        ...state,
        registerInfo,
      }
    }
    default:
      return state
  }
}