import { IpHistoryViewMode } from "../../utils/const"

const SET_IP_HISTORY_VIEW_MODE = 'SET_IP_HISTORY_VIEW_MODE'

export const setIpHistoryViewModeAction = mode => ({
  type: SET_IP_HISTORY_VIEW_MODE,
  payload: { mode }
})

const initialState = {
  viewMode: IpHistoryViewMode.VIEW_ALL,
}

export default function uiIpHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IP_HISTORY_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.mode
      }
    default:
      return state
  }
}