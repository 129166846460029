import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import { dbUpdateCookieSettings } from "./api";
import { Link } from "react-router-dom";

const getSesssionCookieSettings = () => {
  const item = sessionStorage.getItem("cookie_settings");
  if (item === "null" || !item) {
    return null;
  }

  return item;
};

const setSessionCookieSettings = (value) => {
  sessionStorage.setItem("cookie_settings", value);
};

const CookieBanner = (props) => {
  const { account } = props;
  const [cookieSettings, setCookieSettings] = useState(
    getSesssionCookieSettings()
  );

  useEffect(() => {
    // Save cookie settings in session if database has no cookie preference for user
    if (!account.isAuthenticated) return;

    if (
      cookieSettings !== null &&
      account.accountData &&
      account.accountData.cookie_settings === null
    ) {
      // Save it to db from session
      dbUpdateCookieSettings(cookieSettings);
    }
  }, [account]);

  useEffect(() => {
    // Synchronize local state with session state
    if (account.isAuthenticated) {
      dbUpdateCookieSettings(cookieSettings);
    }

    setSessionCookieSettings(cookieSettings);
  }, [cookieSettings]);

  const authenticatedHasDBCookieSettings =
    account.isAuthenticated &&
    account.accountData &&
    account.accountData.cookie_settings === null;

  const unAuthenticatedHasLocalCookieSettings =
    !account.isAuthenticated && cookieSettings === null;

  const authenticatedAndHasLocalCookieSettings =
    account.isAuthenticated && cookieSettings !== null;

  if (
    (authenticatedHasDBCookieSettings &&
      !authenticatedAndHasLocalCookieSettings) ||
    unAuthenticatedHasLocalCookieSettings
  ) {
    return (
      <div
        style={{
          zIndex: 1000,
          backgroundColor: "#ebebeb",
          height: "70px",
          width: "100%",
          position: "fixed",
          padding: "10px",
          bottom: 0,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={12}>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p>
                  This site uses cookies to offer you a better browsing
                  experience. 
                  <Link to="/pages/cookies" style={{padding: "10px"}}>
                     Find out more on how we use cookies.
                  </Link>
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={6}>
                    <Button
                      style={{ height: "50px", width: "150px" }}
                      onClick={() => {
                        setCookieSettings("allowed_all");
                      }}
                    >
                      Accept all cookies
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      style={{ height: "50px", width: "150px" }}
                      onClick={() => {
                        setCookieSettings("allowed_necessary");
                      }}
                    >
                      Accept only essential cookies
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }

  return <></>;
};

const mapStateToProp = (state) => ({
  account: state.entity.account,
});

export default connect(mapStateToProp)(CookieBanner);
