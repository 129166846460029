import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  let middleware = applyMiddleware(sagaMiddleware)
  const store = createStore(
    rootReducer,
    composeWithDevTools(middleware),
  )

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore()