import axios from 'axios';
import { getNormalizedData } from '../../utils/service';

const LOCAL_STORAGE_CONST = {
  ACCESS_TOKEN: "accessToken",
  DEVICE_ID: "deviceId",
}

const ACCOUNT_REFRESH_BY_ACCESS_TOKEN = 'ACCOUNT_REFRESH_BY_ACCESS_TOKEN'
const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN'
const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'
const ACCOUNT_UPDATE_DATA = 'ACCOUNT_UPDATE_DATA'
const SET_DEVICE_ID = 'SET_DEVICE_ID'
const SET_NEED_TO_SEND_TOKEN = 'SET_NEED_TO_SEND_TOKEN'
const SET_FCM_TOKEN = 'SET_FCM_TOKEN'

export const accountLoginAction = (accessToken, accountData) => ({
  type: ACCOUNT_LOGIN,
  payload: { accessToken, accountData }
})

export const accountRefreshByAccessTokenAction = (accountData) => ({
  type: ACCOUNT_REFRESH_BY_ACCESS_TOKEN,
  payload: { accountData }
})

export const accountLogoutAction = () => ({
  type: ACCOUNT_LOGOUT,
})

export const accountUpdateDataAction = accountData => ({
  type: ACCOUNT_UPDATE_DATA,
  payload: { accountData }
})

export const setDeviceIdAction = deviceId => ({
  type: SET_DEVICE_ID,
  payload: { deviceId }
})

export const setNeedToSendTokenAction = isNeed => ({
  type: SET_NEED_TO_SEND_TOKEN,
  payload: { isNeed }
})

export const setFCMTokenAction = token => ({
  type: SET_FCM_TOKEN,
  payload: { token }
})


const loadAccessToken = () => {
  let accessToken = localStorage.getItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN)
  if (null !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
  } else {
    accessToken = undefined
  }

  return accessToken
}

const saveAccessToken = accessToken => {
  if (undefined !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
    localStorage.setItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN, accessToken)
  } else {
    axios.defaults.headers.common['Authorization'] = undefined
    localStorage.removeItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN)
  }
}

const loadDeviceId = () => {
  const deviceId = localStorage.getItem(LOCAL_STORAGE_CONST.DEVICE_ID)
  return null === deviceId ? undefined : parseInt(deviceId)
}

const saveDeviceId = deviceId => {
  if (undefined === deviceId) {
    localStorage.removeItem(LOCAL_STORAGE_CONST.DEVICE_ID)
  } else {
    localStorage.setItem(LOCAL_STORAGE_CONST.DEVICE_ID, deviceId)
  }
}

const initialState = {
  accessToken: loadAccessToken(),
  isAuthenticated: false,
  accountData: {},
  deviceId: loadDeviceId(),
  needToSendToken: true,
  fcmToken: undefined,
}

export default function entityAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LOGIN: {
      saveAccessToken(action.payload.accessToken)
      return {
        ...state,
        accessToken: action.payload.accessToken,
        accountData: action.payload.accountData,
        isAuthenticated: true,
      }
    }
    case ACCOUNT_LOGOUT: {
      saveAccessToken(undefined)
      saveDeviceId(undefined)
      return {
        ...state,
        accessToken: undefined,
        deviceId: undefined,
        accountData: {},
        isAuthenticated: false,
        fcmToken: undefined,
      }
    }
    case ACCOUNT_REFRESH_BY_ACCESS_TOKEN: {
      return {
        ...state,
        accountData: action.payload.accountData,
        isAuthenticated: true,
      }
    }
    case ACCOUNT_UPDATE_DATA: {
      return {
        ...state,
        accountData: action.payload.accountData,
      }
    }
    case SET_DEVICE_ID:
      saveDeviceId(action.payload.deviceId)
      return {
        ...state,
        deviceId: action.payload.deviceId,
      }
    case SET_NEED_TO_SEND_TOKEN: 
      return {
        ...state,
        needToSendToken: action.payload.isNeed,
      }
    case SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action.payload.token,
      }
    default:
      return state
  }
}