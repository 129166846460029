const SET_MONITOR_VIEW_MODE = "SET_MONITOR_VIEW_MODE";

export const setMonitorViewModeAction = (mode) => ({
  type: SET_MONITOR_VIEW_MODE,
  payload: { mode },
});

const initialState = {};

export default function uiMonitorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MONITOR_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.mode,
      };
    default:
      return state;
  }
}
