const SET_SEARCH_HISTORY_DATA = "SET_SEARCH_HISTORY_DATA";

export const setSearchHistoryDataAction = (data) => ({
  type: SET_SEARCH_HISTORY_DATA,
  payload: { data },
});

const initialState = {
  data: undefined,
};

export default function entitySearchHistoryReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_SEARCH_HISTORY_DATA:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
